import React, { useContext } from 'react';

import AutocompleteAddressInput from '~/components/inputs/autocomplete-address-input/autocomplete-address-input';
import { FormContext } from '~/components/form/form';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';

/**
 * Wrapper around the <AutocompleteAddressInput /> component so it can
 * be used in our <Field /> abstraction within a <Form />
 */
const AutocompleteAddressField = ({
  meta,
  input,
  label,
  type = AutocompleteTypes.FULL_ADDRESS,
}) => {
  const { state: formState, handleFieldChange } = useContext(FormContext);

  const handleSelectAddress = (address) => {
    handleFieldChange(input.name, address);
  };

  return (
    <AutocompleteAddressInput
      label={label}
      labelClassname={input?.labelClassname}
      name={input.name}
      placeholder={input.placeholder}
      onSelect={handleSelectAddress}
      error={meta.error}
      submitFailed={formState.submitFailed}
      data-valid={input['data-valid']}
      type={type}
      initialValue={input?.initialValue}
    />
  );
};

export default AutocompleteAddressField;
